import { Injectable } from '@angular/core';
import { Amplify, Auth } from 'aws-amplify';
import { AppConfig } from '../shared/utils/config/app-config';
import { CognitoSecrets } from '../models/cognito/cognito-secrets';
import { CognitoAuthConfig } from '../models/cognito/cognito-auth-config';
import { CognitoOauth } from '../models/cognito/cognito-oauth';

@Injectable({
	providedIn: 'root'
})
export class CognitoService {
	load(): void {
		const cognitoAuth = this.getCognitoAuthConfig(AppConfig.instance.cognitoSecrets);
		Amplify.configure({
			// Auth: ,
			Auth: cognitoAuth,
			API: {
				endpoints: [
					{
						name: 'MyAPIGateway',
						endpoint: AppConfig.instance.backendUrl,
						custom_header: async () => {
							return {
								Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
							};
						}
					}
				]
			}
		});
	}

	private getCognitoAuthConfig(cognitoSecrets: CognitoSecrets): CognitoAuthConfig {
		const poolId = this.getPoolIdFromIssuer(cognitoSecrets.issuer);
		const regionValue = this.getRegionFromPoolId(poolId);
		const domainValue = this.getDomainFromAuthorizationEndpoint(cognitoSecrets.authorization_endpoint);
		const cognitoOauth: CognitoOauth = {
			domain: domainValue,
			redirectSignOut: AppConfig.instance.redirectURL,
			redirectSignIn: AppConfig.instance.redirectURL,
			responseType: 'code'
		};
		return {
			userPoolId: poolId,
			userPoolWebClientId: cognitoSecrets.client_id,
			region: regionValue,
			oauth: cognitoOauth
		};
	}

	private getPoolIdFromIssuer(issuer: string): string {
		return issuer.substring(issuer.lastIndexOf('/') + 1);
	}

	private getRegionFromPoolId(poolId: string): string {
		return poolId.substring(0, poolId.lastIndexOf('_'));
	}

	private getDomainFromAuthorizationEndpoint(authorizationEndpoint: string): string {
		const domain = authorizationEndpoint.replace('https://', '');
		return domain.substring(0, domain.indexOf('/'));
	}
}
