import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class ThemeProcessorService {
	changeTheme(market: string, tool: string) {
		market = market.toLowerCase();
		tool = tool.toLowerCase();

		localStorage.setItem('market', market);
		localStorage.setItem('tool', tool);

		const prefix = '--' + tool + '-' + market + '-';

		const mainColor = getComputedStyle(document.documentElement).getPropertyValue(prefix + 'main-color');
		const mainColorGradient = getComputedStyle(document.documentElement).getPropertyValue(prefix + 'main-color-gradient');
		const boxColorGradient = getComputedStyle(document.documentElement).getPropertyValue(prefix + 'box-color-gradient');
		const titleColorGradient = getComputedStyle(document.documentElement).getPropertyValue(
			prefix + 'title-color-gradient'
		);
		const timelineIcon = getComputedStyle(document.documentElement).getPropertyValue(prefix + 'timeline-icon-color');
		const timelineTitle = getComputedStyle(document.documentElement).getPropertyValue(
			prefix + 'timeline-title-color-gradient'
		);

		document.documentElement.style.setProperty('--theme-color', mainColor);
		document.documentElement.style.setProperty('--theme-color-gradient', mainColorGradient);
		document.documentElement.style.setProperty('--theme-color-box', boxColorGradient);
		document.documentElement.style.setProperty('--theme-color-title', titleColorGradient);
		document.documentElement.style.setProperty('--theme-icon-timeline', timelineIcon);
		document.documentElement.style.setProperty('--theme-icon-timeline-title', timelineTitle);
	}
}
