import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClrDatagridStateInterface } from '@clr/angular';

@Injectable()
export class Convertor {
	convertDatagridStateToQueryParam(
		state: ClrDatagridStateInterface,
		defaultSort: { [key: string]: 'asc' | 'desc' } = null
	): HttpParams {
		let params = new HttpParams();

		if (state.page && state.page.from !== undefined && state.page.size !== undefined) {
			params = params.append('page', (state.page.current - 1).toString());
			params = params.append('size', state.page.size.toString());
		} else {
			params = params.append('page', '0');
			params = params.append('size', '10');
		}

		if (state.sort) {
			params = params.append('sort', state.sort.by.toString() + ',' + (state.sort.reverse ? 'desc' : 'asc'));
		} else if (defaultSort) {
			Object.keys(defaultSort).forEach((property) => {
				params = params.append('sort', property + ',' + defaultSort[property]);
			});
		}

		/*let search = '';
    if (state.filters) {
      for (const { property, value } of state.filters) {
        search += `${property}=${encodeURIComponent(value)},`;
      }
      params = params.append('search', search);
    }*/
		return params;
	}
}
