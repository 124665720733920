import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
	handleError(err: any): void {
		if (err && err.stack) {
			console.log(err.stack.toString());
		}
	}
}
