import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ThemeProcessorService } from './theme-processor.service';

@Injectable({
	providedIn: 'root'
})
export class ThemeDisplayService {
	constructor(private themeProcessor: ThemeProcessorService) {}

	refreshTheme() {
		if (localStorage.getItem('market') != null && localStorage.getItem('tool') != null) {
			this.displayMarketTheme(localStorage.getItem('market').toLowerCase(), localStorage.getItem('tool').toLowerCase());
		}
	}

	displayMarketTheme(market: string, tool: string) {
		if ((market && localStorage.getItem('market') !== market) || (tool && localStorage.getItem('tool') !== tool)) {
			this.themeProcessor.changeTheme(market, tool);
		}
	}

	displayMarketThemeFromRoute(routeSnapshot: ActivatedRouteSnapshot) {
		const urlSegmentMarketList = routeSnapshot?.url?.filter((url) => url.path === 'b2c' || url.path === 'b2b');

		if (urlSegmentMarketList.length > 0 && urlSegmentMarketList[0]?.path) {
			//TODO: clarify for the tool part
			this.displayMarketTheme(urlSegmentMarketList[0]?.path, localStorage.getItem('tool'));
		}
	}
}
