import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

export class RegistrationRouteReuseStrategy implements RouteReuseStrategy {
	public shouldDetach(): boolean {
		return false;
	}
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	public store(): void {}

	public shouldAttach(): boolean {
		return false;
	}
	public retrieve(): DetachedRouteHandle {
		return null;
	}
	public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
		if (future.data.reuse !== undefined) {
			return future.data.reuse;
		} else {
			return future.routeConfig === curr.routeConfig;
		}
	}
}
