import { NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { RegistrationRouteReuseStrategy } from './components/registrations/registration-route-reuse-strategy';
import { NotFoundComponent } from './components/common/not-found/not-found.component';

// routes
const appRoutes: Routes = [
	{
		path: '',
		redirectTo: 'dashboard',
		pathMatch: 'full'
	},
	{
		path: 'dashboard',
		loadComponent: () =>
			import('./components/dashboard/page/dashboard-page.component').then((m) => m.DashboardPageComponent)
	},
	{
		path: 'reporting',
		loadChildren: () => import('./components/reporting/reporting.module').then((m) => m.ReportingModule)
	},
	{
		path: 'acquisitions',
		loadChildren: () => import('./components/acquisitions/acquisition.module').then((m) => m.AcquisitionModule)
	},
	{
		path: 'renewal',
		loadChildren: () => import('./components/renewals/renewals.module').then((m) => m.RenewalsModule)
	},
	{
		path: 'monitoring',
		loadChildren: () => import('./components/monitoring/monitoring.module').then((m) => m.MonitoringModule)
	},
	{
		path: 'caches',
		loadChildren: () => import('./components/caches/caches.module').then((m) => m.CachesModule)
	},
	{
		path: 'registrations',
		loadChildren: () => import('./components/registrations/registrations.module').then((m) => m.RegistrationsModule)
	},
	{
		path: 'move',
		loadChildren: () => import('./components/move/move.module').then((m) => m.MoveModule)
	},
	{
		path: 'documents-template-manager',
		loadChildren: () =>
			import('./components/documents-template-manager/documents-template-manager.module').then(
				(m) => m.DocumentsTemplateManagerModule
			)
	},
	{
		path: 'not-found',
		component: NotFoundComponent
	},
	{
		path: '**',
		component: NotFoundComponent
	}
];

@NgModule({
	imports: [RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload' })],
	exports: [RouterModule],
	providers: [
		{
			provide: RouteReuseStrategy,
			useClass: RegistrationRouteReuseStrategy
		}
	]
})
export class AppRoutingModule {}
