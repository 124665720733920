import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { first } from 'rxjs/operators';
import { environment } from '@env/environment';
import { JwtInterceptor } from '@app/services/interceptor/jwt.interceptor';
import { CognitoService } from '@app/services/cognito.service';
import { Config } from './config.model';
import { PipelineData, PipelinesData } from '@app/models/pipeline_data';

@Injectable({
	providedIn: 'root'
})
export class AppConfig {
	private readonly CONFIG_PATH = 'assets/config/json/config.json';
	private readonly DEV_CONFIG_PATH = 'assets/config/json/config.dev.json';
	private readonly PIPELINE_DATA_PATH = 'pipeline_data.json';

	private _config: Config | undefined;
	private _pipelineData: PipelinesData | undefined;

	private emptyPipelineData: PipelinesData = {
		process: {
			pipeline_id: '0',
			pipeline_timestamp: new Date(0)
		},
		backend: {
			pipeline_id: '0',
			pipeline_timestamp: new Date(0)
		},
		frontend: {
			pipeline_id: '0',
			pipeline_timestamp: new Date(0)
		}
	};

	constructor(
		private http: HttpClient,
		private cognitoService: CognitoService,
		private jwt: JwtInterceptor
	) {}

	get instance(): Config {
		return this._config;
	}

	get pipelineData(): PipelinesData {
		return this._pipelineData;
	}

	static instance: Config;

	get(key: string): any {
		return _.get(this._config, key);
	}

	set(key: string, value: any): void {
		return _.set(this._config, key, value);
	}

	public load() {
		return new Promise<void>((resolve, reject) => {
			this.http
				.get(this.DEV_CONFIG_PATH)
				.pipe(first())
				.subscribe({
					next: (config: any) => this.setConfigFile(config, resolve),
					error: (response) => {
						if (response.message.includes('parsing') && !response.error.text.toLowerCase().includes('doctype')) {
							this.tryToParseFailingConfig(response, resolve);
						} else {
							this.http
								.get(this.CONFIG_PATH)
								.pipe(first())
								.subscribe({
									next: (config: Config) => this.setConfigFile(config, resolve),
									error: (response: any) => {
										if (response.message.includes('parsing')) {
											this.tryToParseFailingConfig(response, resolve);
										} else {
											reject(`Could not load file '${this.CONFIG_PATH}': ${JSON.stringify(response)}`);
										}
									}
								});
						}
					}
				});
		});
	}

	public loadPipelineData() {
		return new Promise<PipelinesData>((resolve, reject) => {
			if (!this._config) {
				reject(`Could not load pipeline data from backend: config file not loaded}`);
			}
			this.http
				.get(this.PIPELINE_DATA_PATH)
				.pipe(first())
				.subscribe({
					next: async (frontendData: PipelineData) => {
						const headers = await this.jwt.getHeaders();
						this.http
							.get(this._config.backendUrl + '/ui/pipeline-data', { headers })
							.pipe(first())
							.subscribe({
								next: (data: PipelinesData) => {
									data.frontend = frontendData;
									this._pipelineData = data;
									resolve(data);
								},
								error: (response: any) => {
									console.warn('Could not load pipeline data from backend', response);
									this._pipelineData = this.emptyPipelineData;
									resolve(this.emptyPipelineData);
								}
							});
					},
					error: (response) => {
						console.warn('Could not load pipeline data from backend', response);
						this._pipelineData = this.emptyPipelineData;
						resolve(this.emptyPipelineData);
					}
				});
		});
	}

	private tryToParseFailingConfig(response, resolve) {
		const rawJson: string = response.error.text.replace(/[\s\n]/g, '');
		const rawConfig = rawJson.split('"cognitoSecrets":')[0].slice(0, -1) + '}';
		const rawCognitoSecrets = rawJson.split('"cognitoSecrets":')[1].slice(1, -1).slice(0, -1);
		const config: Config = JSON.parse(rawConfig);
		config.cognitoSecrets = JSON.parse(rawCognitoSecrets);
		this.setConfigFile(config, resolve);
	}

	private setConfigFile(config: Config, resolve) {
		if (environment.useProxy && config.backendUrlWithProxy) {
			config.backendUrl = config.backendUrlWithProxy;
			console.info('Using proxy for backend url: ' + config.backendUrl);
		}
		this._config = config;
		AppConfig.instance = config;
		this.cognitoService.load();
		resolve();
	}
}
