<clr-main-container>
	<app-top-menu></app-top-menu>

	<div class="h-100 content-container">
		<app-left-menu></app-left-menu>

		<div class="content-area">
			<app-services-health-check></app-services-health-check>
			<router-outlet></router-outlet>
		</div>
	</div>
</clr-main-container>
