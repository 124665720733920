<div class="alert alert-danger" role="alert" *ngIf="hasIssue">
	<div class="alert-items">
		<!--region Bundles-->
		<div class="alert-item static" *ngIf="hasIssueWithBundles">
			<div class="alert-icon-wrapper">
				<cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
			</div>
			<span class="alert-text">Could not load bundles</span>
			<div class="alert-actions">
				<a (click)="retryBundleLoading()" style="cursor: pointer; vertical-align: middle" *ngIf="!isLoading">
					<cds-icon shape="refresh" size="12"></cds-icon>
					&nbsp;Retry
				</a>
				<span class="spinner spinner-sm" *ngIf="isLoading">Loading...</span>
			</div>
		</div>
		<!--endregion-->
	</div>
</div>
