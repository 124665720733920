import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class UtilsService {
	static toCamel(s: string): string {
		if (s) {
			return s.replace(/([-_][a-z])/gi, ($1) => $1.toUpperCase().replace('-', '').replace('_', ''));
		} else {
			return '';
		}
	}

	static prune(input) {
		if (typeof input !== 'object') {
			return;
		}

		Object.keys(input).forEach((key) => {
			if (!input[key]) {
				delete input[key];
				return;
			}

			if (typeof input !== 'object') {
				return;
			}

			this.prune(input[key]);
			return;
		});
	}
}
