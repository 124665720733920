import { Component } from '@angular/core';
import { ProductCatalogService } from '@app/services/product-catalog.service';
import { CdsIconModule } from '@cds/angular';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'app-services-health-check',
	templateUrl: './services-health-check.component.html',
	styleUrls: ['./services-health-check.component.scss'],
	imports: [CommonModule, CdsIconModule],
	standalone: true
})
export class ServicesHealthCheckComponent {
	get hasIssue() {
		return this.hasIssueWithBundles;
	}

	get hasIssueWithBundles() {
		return this.productCatalogService.bundlesLoadedSuccessfully === false;
	}

	isLoading = false;

	// Populate with other services
	constructor(private productCatalogService: ProductCatalogService) {}

	public async retryBundleLoading(): Promise<any> {
		this.isLoading = true;
		await this.productCatalogService.loadBundlesAsync();
		this.isLoading = false;
	}
}
