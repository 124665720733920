import { Injectable } from '@angular/core';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { BehaviorSubject } from 'rxjs';

export interface CognitoUserAttributes {
	'custom:groups': string[];
	email: string;
	email_verified: boolean;
	family_name: string;
	given_name: string;
	identities: string;
	name: string;
	sub: string;
}

export interface CognitoUser {
	attributes: CognitoUserAttributes;
	id: string;
	username: string;
}

@Injectable({
	providedIn: 'root'
})
export class AuthCognitoService {
	private authenticationSubject: BehaviorSubject<boolean>;
	private user: CognitoUser;

	constructor() {
		this.authenticationSubject = new BehaviorSubject<boolean>(false);
	}

	public async signOut(): Promise<any> {
		await Auth.signOut();
		this.authenticationSubject.next(false);
	}

	public async isAuthenticated(): Promise<boolean> {
		if (this.authenticationSubject.value) {
			return Promise.resolve(true);
		} else {
			try {
				const user = await this.getUser();
				return !!user;
			} catch (e) {
				this.authenticationSubject.next(false);
				return false;
			}
		}
	}

	public async getUser(): Promise<CognitoUser> {
		if (!this.user) {
			this.user = await Auth.currentAuthenticatedUser();
			this.authenticationSubject.next(true);
		}
		return Promise.resolve(this.user);
	}

	public federatedSignIn(): void {
		Auth.federatedSignIn()
			.then(() => {
				this.authenticationSubject.next(true);
			})
			.catch((e) => console.log(e));
	}

	public getCurrentSession(): Promise<CognitoUserSession> {
		return Auth.currentSession();
	}
}
