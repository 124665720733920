import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { from, Observable } from 'rxjs';
import { AuthCognitoService } from '../auth-cognito.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
	configUrl = 'assets/config/json/config.json';
	devConfigUrl = 'assets/config/json/config.dev.json';

	constructor(private authCognitoService: AuthCognitoService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return from(this.injectJWTToken(request, next));
	}

	async injectJWTToken(request: HttpRequest<any>, next: HttpHandler) {
		// we do not need to inject jwt in the config call
		if (request.url === this.configUrl || request.url === this.devConfigUrl) {
			return next.handle(request).toPromise();
		}
		const isAuthenticated = await this.authCognitoService.isAuthenticated();
		if (isAuthenticated === false) {
			return next.handle(request).toPromise();
		}
		const headers = await this.getHeaders();
		const authReq = request.clone({
			setHeaders: headers,
			/// This prevents an issue with Spring Boot where it throws an error
			/// with / followed by ?
			url: request.url.replace('/?', '?')
		});
		return next.handle(authReq).toPromise();
	}

	async getHeaders() {
		const currentSession: CognitoUserSession = await this.authCognitoService.getCurrentSession();
		const cognitoUser = await this.authCognitoService.getUser();
		const sAMAccountName = cognitoUser.attributes['custom:samaccountname'];
		localStorage.setItem('sAMAccountName', sAMAccountName);

		return {
			Authorization: 'Bearer ' + currentSession.getIdToken().getJwtToken(),
			sAMAccountName: sAMAccountName
		};
	}
}
