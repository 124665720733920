import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserService } from '../services/user.service';
import { AccessRights } from '@app/models/user';

@Directive({
	selector: '[hasAccess]',
	standalone: true
})
export class HasAccessDirective {
	constructor(
		private userService: UserService,
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef
	) {}

	@Input()
	set hasAccess(value: AccessRights) {
		if (this.userService.hasAccess(value)) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		}
	}
}
