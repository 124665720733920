import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClarityModule } from '@clr/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { initializer } from './utils/app-init';

import { registerLocaleData } from '@angular/common';
import { CdsModule } from '@cds/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { GlobalErrorHandler } from './global-error-handler';
import { JwtInterceptor } from './services/interceptor/jwt.interceptor';
import localeFrBeExtra from '@angular/common/locales/extra/fr-BE';
import localeFrBe from '@angular/common/locales/fr-BE';
import { AuthCognitoService } from './services/auth-cognito.service';
import { UserService } from './shared/services/user.service';
import { SharedModule } from './shared/shared.module';
import { AppConfig } from './shared/utils/config/app-config';
import { ThemeDisplayService } from './services/theme-display.service';
import { ThemeProcessorService } from './services/theme-processor.service';
import { SalesContextService } from './services/sales-context.service';
import { ApplicationTopMenuComponent } from '@app/components/common/menu/top/application-top-menu.component';
import { ApplicationLeftMenuComponent } from '@app/components/common/menu/left/application-left-menu.component';
import {
	ServicesHealthCheckComponent
} from '@app/components/common/services-health-check/services-health-check.component';

registerLocaleData(localeFrBe, 'fr-BE', localeFrBeExtra);

export function HttpLoaderFactory(httpClient: HttpClient) {
	return new TranslateHttpLoader(httpClient);
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		ClarityModule,
		BrowserAnimationsModule,
		TranslateModule,
		SharedModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		ToastrModule.forRoot({
			timeOut: 4000,
			positionClass: 'toast-bottom-right'
		}),
		CdsModule,
		ApplicationTopMenuComponent,
		ApplicationLeftMenuComponent,
		ServicesHealthCheckComponent
	],
	providers: [
		ThemeProcessorService,
		{
			provide: ErrorHandler,
			useClass: GlobalErrorHandler
		},
		ThemeDisplayService,
		{ provide: LOCALE_ID, useValue: 'fr-BE' },
		{
			provide: APP_INITIALIZER,
			useFactory: initializer,
			multi: true,
			deps: [UserService, AppConfig, AuthCognitoService, SalesContextService]
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: JwtInterceptor,
			multi: true
		}
	],
	exports: [],
	bootstrap: [AppComponent]
})
export class AppModule {}
