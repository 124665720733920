import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfig } from '../shared/utils/config/app-config';
import { Providers } from '../models/providers';
import { MIGInfo } from '../models/preference';
import { ApplicationStatus } from '../models/monitoring';
import { Reason } from '../models/reason';

@Injectable({
	providedIn: 'root'
})
export class ResourceService {
	constructor(
		private http: HttpClient,
		private config: AppConfig
	) {}

	providers: Providers;
	migInfo: MIGInfo;

	getProvidersBy(region: string, segment: string, energy: string): Observable<string[]> {
		return this.getProviders().pipe(
			map((p: Providers) => {
				if (p) {
					let seg = p.b2c;
					if ('b2b' === segment.toLowerCase()) {
						seg = p.b2b;
					}
					let providers = seg.electricity;
					if ('gas' === energy.toLowerCase()) {
						providers = seg.gas;
					}
					switch (region.toLowerCase()) {
						case 'wal':
							return providers.wal;
						case 'fla':
							return providers.fla;
						case 'bxl':
						case 'bru':
							return providers.bxl;
					}
				}
				return [];
			})
		);
	}

	getProviders(): Observable<Providers> {
		if (this.providers && this.providers.b2c && this.providers.b2c.electricity) {
			return of(this.providers);
		}
		return this.http.get<Providers>(`${this.config.instance.backendUrl}/ui/energy-providers`).pipe(
			map((p: Providers) => {
				this.providers = p;
				return p;
			})
		);
	}

	getMigInfo(): Observable<MIGInfo> {
		if (this.migInfo) {
			return of(this.migInfo);
		}
		return this.http.get<MIGInfo>(`${this.config.instance.backendUrl}/api/v1/resources/mig`).pipe(
			map((p: MIGInfo) => {
				this.migInfo = p;
				return p;
			})
		);
	}

	getApplicationStatus(): Observable<ApplicationStatus> {
		return this.http.get<ApplicationStatus>(`${this.config.instance.backendUrl}/api/v1/monitoring/external-services`);
	}

	getReasons(): Observable<Reason[]> {
		return this.http.get<Reason[]>(`${this.config.instance.backendUrl}/api/v1/archives/reasons`);
	}

	getReasonsBy(category: string, segment: string) {
		let params = new HttpParams();
		params = params.append('category', category);
		return this.http.get<Reason[]>(`${this.config.instance.backendUrl}/api/v1/archives/reasons-by-segment/${segment}`, {
			params
		});
	}

	getSubReasons(category: string, reasonCode: string, reasonSegment: string) {
		let params = new HttpParams();
		params = params.append('category', category);
		return this.http.get<Reason[]>(
			`${this.config.instance.backendUrl}/api/v1/archives/${reasonSegment}/sub-reasons/${reasonCode}`,
			{ params }
		);
	}
}
