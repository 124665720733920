import { Component } from '@angular/core';
import { CdsIconModule } from '@cds/angular';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'app-not-found',
	templateUrl: './not-found.component.html',
	styleUrls: ['./not-found.component.scss'],
	imports: [CommonModule, CdsIconModule, RouterLink],
	standalone: true
})
export class NotFoundComponent {}
