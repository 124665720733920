import { Component } from '@angular/core';
import { AccessRights } from 'src/app/models/user';
import { AppConfig } from '@app/shared/utils/config/app-config';
import { PipelinesData } from '@app/models/pipeline_data';
import { ClrVerticalNavModule } from '@clr/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { CdsIconModule } from '@cds/angular';
import { HasAccessDirective } from '@app/shared/directives/has-access.directive';

declare let require: any;

const { version: appVersion } = require('../../../../../../package.json');

@Component({
	selector: 'app-left-menu',
	templateUrl: './application-left-menu.component.html',
	styleUrls: ['./application-left-menu.component.scss'],
	imports: [
		CommonModule,
		ClrVerticalNavModule,
		TranslateModule,
		RouterLink,
		CdsIconModule,
		RouterLinkActive,
		HasAccessDirective
	],
	standalone: true
})
export class ApplicationLeftMenuComponent {
	public appVersion;
	readonly AccessRights = AccessRights;
	collapsible = true;
	collapse = true;

	constructor(private appConfig: AppConfig) {
		this.appVersion = appVersion;
	}

	get pipelineData(): PipelinesData {
		return this.appConfig.pipelineData;
	}
}
