import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { Config } from '@app/shared/utils/config/config.model';

function tryToParseFailingConfig(rawData: string): Promise<Config> {
	return new Promise<Config>((resolve, reject) => {
		try {
			const rawJson: string = rawData.replace(/[\s\n]/g, '');
			const rawConfig = rawJson.split('"cognitoSecrets":')[0].slice(0, -1) + '}';
			const rawCognitoSecrets = rawJson.split('"cognitoSecrets":')[1].slice(1, -1).slice(0, -1);
			const config: Config = JSON.parse(rawConfig);
			config.cognitoSecrets = JSON.parse(rawCognitoSecrets);
			resolve(config);
		} catch (e) {
			reject(e);
		}
	});
}

fetch(`./assets/config/json/config.json`)
	.then((response) => {
		return response.json();
	})
	.catch((error) => {
		console.info('Error while fetching config.json, trying to parse it manually\n=>', error);
		return fetch(`./assets/config/json/config.json`).then(async (response) => {
			const rawData = await response.text();
			return tryToParseFailingConfig(rawData);
		});
	})
	.then((config: Config) => {
		if (config.production) {
			enableProdMode();
		}

		platformBrowserDynamic()
			.bootstrapModule(AppModule)
			.catch((err) => console.error(err));
	});
