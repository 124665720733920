import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CdsIconModule } from '@cds/angular';
import { ClrDatagridModule } from '@clr/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AddressPipe } from './pipes/address.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { RecordsTableComponent } from './components/records-table/records-table.component';
import { DropzoneDirective } from './directives/dropzone.directive';
import { SprintfPipe } from './pipes/sprintf.pipe';
import { JwtInterceptor } from '../services/interceptor/jwt.interceptor';
import { RequiredFieldDirective } from './directives/required-field.directive';
import { Convertor } from '../utils/convertor';

@NgModule({
	declarations: [
		SprintfPipe,
		DropzoneDirective,
		ReplacePipe,
		AddressPipe,
		RecordsTableComponent,
		RequiredFieldDirective
	],
	imports: [CommonModule, CdsIconModule, ClrDatagridModule, TranslateModule, RouterLink],
	exports: [
		//DIRECTIVE
		DropzoneDirective,
		RequiredFieldDirective,

		//PIPE
		ReplacePipe,
		SprintfPipe,
		AddressPipe,

		//COMPONENT
		RecordsTableComponent,

		//MODULE
		TranslateModule
	],
	providers: [JwtInterceptor, DatePipe, Convertor]
})
export class SharedModule {}
