import { AuthCognitoService } from '../services/auth-cognito.service';
import { UserService } from '../shared/services/user.service';
import { AppConfig } from '../shared/utils/config/app-config';
import { SalesContextService } from '../services/sales-context.service';

export const initializer =
	(
		userService: UserService,
		config: AppConfig,
		cognitoService: AuthCognitoService,
		salesContextService: SalesContextService
	): (() => Promise<any>) =>
	(): Promise<any> =>
		// eslint-disable-next-line no-async-promise-executor
		new Promise(async (resolve, reject) => {
			try {
				await config.load();
				const isAuthenticated = await cognitoService.isAuthenticated();
				if (!isAuthenticated) {
					cognitoService.federatedSignIn();
				}
				const userDetails = await cognitoService.getUser();
				userService.setUserFromCognito(userDetails);
				if (config.instance.production) {
					await config.loadPipelineData();
				}
				await salesContextService.loadSalesContexts();
				resolve(true);
			} catch (error) {
				console.log(error);
				reject(error);
			}
		});
